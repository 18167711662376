import React from "react"
import { navigate } from "gatsby"
// import CookieConsent from "react-cookie-consent


export default function App() {
  typeof window !== `undefined` && navigate("/user")

  return (
    <div>
    </div>
  )
}
